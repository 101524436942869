<template>
  <div class="h-screen flex w-full vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2
            v-html="$t('Title', {
            company: this.optOutInfo.company,
            opt: this.$options.filters.lowercase(this.subscriptionMsg),
          })"
            class="text-center">
          </h2>
        </div>

        <vs-divider position="center">
          {{ this.optKeyword | lowercase }}
        </vs-divider>

        <div class="subscription text-center">

          <span v-html="optMsg"></span>
          <vs-button
            class="w-full mt-4"
            @click="contactOptOut">
            {{ $t('ConfirmButtonText', {
            opt: this.$options.filters.lowercase(this.optKeyword),
            }) }}
          </vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import contactService from '@/api/modules/contact.service';
/**
 * Page of auth, wrap the login and register components
 *
 * @module views/pages/ContactOptOut
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [contactId=''] - contact id for opt
 * @vue-data {string} [optOutToken=''] - token for opt operations
 * @vue-data {Object} [optOutInfo={...}] - opt info
 * @vue-event {void} fetchData - fetch opt information
 * @vue-event {void} contactOptOut - set opt operation
 */
export default {
  i18n: {
    messages: {
      en: {
        Title: 'You are <strong>{opt}</strong> to <strong>{company}</strong> marketing notifications.',
        OptOutMsg: 'If you no longer wish to receive marketing notifications from <strong>{company}</strong> press the button below to confirm.',
        OptInMsg: 'If you would like to opt back in to receive marketing notifications from <strong>{company}</strong> press the button below to confirm.',
        ConfirmButtonText: 'Confirm {opt}',
        OptOutSuccessfulTitle: '{opt} successful',
        OptOutSuccessfulMsg: 'You will no longer receive marketing notifications from <strong>{company}</strong>',
        OptInSuccessfulMsg: 'You will receive marketing notifications from <strong>{company}</strong>',
      },
    },
  },
  data() {
    return {
      contactId: '',
      optOutToken: '',
      optOutInfo: {
        company: '',
        optOut: false,
      },
    };
  },
  computed: {
    optKeyword() {
      return this.$tc(`$General.${this.optOutInfo.optOut ? 'OptIn' : 'OptOut'}`);
    },
    optMsg() {
      return this.$t(`${this.optOutInfo.optOut ? 'OptInMsg' : 'OptOutMsg'}`, {
        company: this.optOutInfo.company,
      });
    },
    subscriptionMsg() {
      return this.$t(`$General.${this.optOutInfo.optOut ? 'Unsubscribed' : 'Subscribed'}`);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (this.$route.params.contactId && this.$route.params.optOutToken) {
        this.contactId = this.$route.params.contactId;
        this.optOutToken = this.$route.params.optOutToken;

        const resp = await contactService.optOutInfo({
          contactId: this.contactId,
          optOutToken: this.optOutToken,
        });

        this.optOutInfo = resp.data;
      }
    },
    async contactOptOut() {
      try {
        const optKeyword = this.optKeyword;

        this.$vs.loading({ type: 'radius' });
        const resp = await contactService.optOut({
          contactId: this.contactId,
          optOutToken: this.optOutToken,
        });
        this.$vs.loading.close();

        this.optOutInfo.optOut = resp.data;

        this.$showSuccessActionNotification({
          title: this.$t('OptOutSuccessfulTitle', { opt: optKeyword }),
          text: this.$t(`${this.optOutInfo.optOut ? 'OptOutSuccessfulMsg' : 'OptInSuccessfulMsg'}`, {
            company: this.optOutInfo.company,
          }),
        });
      } catch (e) {
        this.optOutInfo.optOut = false;
      }
    },
  },
};
</script>
